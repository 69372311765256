@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-sharebuttons/themes/default';
// @import 'node_modules/bootstrap-icons/font/bootstrap-icons.css';
// @import 'node_modules/ngx-sharebuttons/themes/circles.scss';